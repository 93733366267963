/*
 * @Author: 故乡情
 * @Date: 2023-08-18 00:27:51
 * @LastEditTime: 2023-09-13 18:55:22
 * @LastEditors: 故乡情
 * @Description: WuWu Project-Wap/PC
 * @FilePath: \wuwu-web\src\components\popular.tsx
 */

export default function Popular() {

    return (
        <div className="container mb-5 popular">
            <div className="title">热门服务<span>专业工程师，快速上门解决问题</span></div>
            <div className="row my-3">
                <div className="col-6 col-md-3">
                    <div className="card" style={{'height': '100%'}}>
                        <img src="http://st.epnz.com/upload/original/20220617/980cb274aa563740a760fce434d8630e.jpg" className="img-fluid object-fit-none" alt="电路维修" />
                        <div className="card-body">
                            <h5 className="card-title"><strong>电路维修</strong></h5>
                            <p className="card-text">家庭、商业电路维修，快速响应，及时解决问题</p>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-3">
                    <div className="card" style={{'height': '100%'}}>
                        <img src="http://st.epnz.com/upload/original/20220617/d8a2c12e9267e7f5349a8210d3dfeef7.jpg" className="img-fluid object-fit-none" alt="空调维修" />
                        <div className="card-body">
                            <h5 className="card-title"><strong>空调维修</strong></h5>
                            <p className="card-text">空调维修，空调移机，空调安装，空调加冷媒，空调清洗……一站服务，快速解决问题</p>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-3">
                    <div className="card" style={{'height': '100%'}}>
                        <img src="http://st.epnz.com/upload/original/20220617/427bcfc5b6c1efb8ec54427ca1a451f3.jpg" className="img-fluid object-fit-none" alt="洁具维修" />
                        <div className="card-body">
                            <h5 className="card-title"><strong>洁具维修</strong></h5>
                            <p className="card-text">家庭卫浴、洁具安装/维修，厨卫故障排除，家庭保洁</p>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-3">
                    <div className="card" style={{'height': '100%'}}>
                        <img src="http://st.epnz.com/upload/original/20220618/cdf5cc6c6ba824ea0f6b4befc9b5c9ed.jpg" className="img-fluid object-fit-none" alt="管道疏通" />
                        <div className="card-body">
                            <h5 className="card-title"><strong>管道疏通</strong></h5>
                            <p className="card-text">马桶、蹲便、地漏、洗手盆、洗菜池……疏通，家庭保洁</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}