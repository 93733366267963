import { useEffect, useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { WU } from '../config/app';

export default function Item() {

    interface Item {
        value: number
        label: string
        children: any
    }

    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false)
    // const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = (title:string, info: string) => {
        setModelTitle(title)
        setModelInfo(info)
        setShow1(true)
    };

    const [item, setItem] = useState<Array<Item>>([])
    const [itemLoad, setItemLoad] = useState(0)
    const [itemTitle, setItemTitle] = useState('')
    const [itemImage, setItemImage] = useState('')
    const [itemId, setItemId] = useState(0)

    const [product, setProduct] = useState([])
    const [productLoad, setProductLoad] = useState(0)
    const [productId, setProductId] = useState(0)

    const [service, setService] = useState([])
    const [serviceLoad, setServiceLoad] = useState(0)

    const [modalTitle, setModelTitle] = useState('')
    const [modelInfo, setModelInfo] = useState('')


    function clickItem(title: any, image: any, id: number) {
        setItemTitle(title)
        setItemImage(image)
        setItemId(id)
        console.log(id)

        setProductId(0)
        setShow(true)
    }

    const clickProductItem = (id: number) => {
        setProductId(id)
        console.log(id)
    }


    useEffect(() => {

        fetch(WU.webApiUrl + 'product.index.html')
            .then(response => response.json())
            .then((data: any) => {
                console.log(data.data)
                setItem(data.data)
                setItemLoad(Object.keys(data.data).length)
            })

        fetch(WU.webApiUrl + '/product.index/item.html', {}).then(response => response.json()).then((data: any) => {
            console.log(data.data)
            setProduct(data.data)
            setProductLoad(Object.keys(data.data).length)
        })

        fetch(WU.webApiUrl + '/service.index.html', {}).then(response => response.json()).then((data: any) => {
            console.log(data.data)
            setService(data.data)
            setServiceLoad(Object.keys(data.data).length)
        })

        return () => {
            // 相当于 componentWillUnmount
        }
    }, [])

    return (
        <>
            <div className='row g-1'>
                {
                    itemLoad ? (
                        item.map((value: any, index: number) =>
                            <div key={index} className='col-6 col-md-3' onClick={() => clickItem(value.label, value.image, index)}>
                                <div className='block'>
                                    <img src={WU.webStaticUrl + value.image} alt={value.label} />
                                    <div className='title'>{value.label}</div>
                                </div>
                            </div>
                        )
                    ) : (
                        <div className={`col-4 `}></div>
                    )
                }
            </div>
            <Offcanvas show={show} onHide={handleClose} placement='end'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title><strong>{itemTitle}</strong></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <img src={WU.webStaticUrl + itemImage} className="img-fluid" alt={itemTitle} />
                    <div className='row g-2 productList'>
                        {
                            itemLoad ? (
                                item[itemId].children.map((value: any, index: number) =>
                                    <div key={index} className='col-4' onClick={() => clickProductItem(value.value)}>
                                        <div className='block'>
                                            <img src={WU.webStaticUrl + value.image} className="img-fluid" alt={value.label} />
                                            <div className={'title' + (value.value === productId ? ' action' : '')}>{value.label}</div>
                                        </div>
                                    </div>
                                )
                            ) : (
                                <div className={`col-4 `}></div>
                            )
                        }
                    </div>
                    <div className='row productInfo'>
                        <div className='col'>
                            {
                                productId ? (
                                    <>
                                        <div className='title'>服务项目</div>
                                        <div className='list'>
                                            {
                                                product.map((value: any, index: number) =>
                                                    value.par_id === productId ? (
                                                        <div key={index} className='row g-1' onClick={() => handleShow1(value.name, value.directions)}>
                                                            <div className='col-9'>{value.name} <i className="bi bi-exclamation-circle"></i></div>
                                                            {value.price === 1 ?
                                                                <div className='col-3 text-end'>面议</div>
                                                                :
                                                                <div className='col-3 text-end'>&yen;{value.price}</div>
                                                            }
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )
                                                )
                                            }
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )
                            }
                        </div>

                    </div>
                </Offcanvas.Body>
            </Offcanvas >

            <Modal show={show1} onHide={handleClose1} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modelInfo}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose1}>
                        我知道了
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}

