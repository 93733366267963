import React, { useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'

import Header from './components/header'
import Item from './components/item'
import Popular from './components/popular'
import Footer from './components/footer'
// import Brand from './components/brand'

import './assets/scss/index.scss'
import ad01 from './assets/image/shop.png'
// import logo from './assets/icon/wuwu-app-logo.svg'


function App() {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const [ofTitle, setOfTitle] = useState('服务保障')
    const [ofInfo, setOfinfo] = useState((
        <div className='row'>
            <div className='col-3'>持证上岗</div>
            <div className='col-3'>专业资质</div>
            <div className='col-3'>品质配件</div>
            <div className='col-3'>售后质保</div>
            <div className='col-3'>返修免费</div>
            <div className='col-3'>微笑服务</div>
            <div className='col-3'>快速响应</div>
            <div className='col-3'>免费咨询</div>
        </div>
    ))

    const openOffcanvas = (id: number) => {
        if (id === 1) {
            setOfTitle('服务保障')
            setOfinfo((
                <div className='row'>
                    <div className='col-3'>持证上岗</div>
                    <div className='col-3'>专业资质</div>
                    <div className='col-3'>品质配件</div>
                    <div className='col-3'>售后质保</div>
                    <div className='col-3'>返修免费</div>
                    <div className='col-3'>微笑服务</div>
                    <div className='col-3'>快速响应</div>
                    <div className='col-3'>免费咨询</div>
                </div>
            ))
            setShow(true)
        }

        if (id === 2) {
            setOfTitle('服务流程')
            setOfinfo((
                <div className='row'>
                    <div className='col-3'>咨询客服</div>
                    <div className='col-3'>确认下单</div>
                    <div className='col-3'>预约时间</div>
                    <div className='col-3'>师傅上门</div>
                    <div className='col-3'>确认价格</div>
                    <div className='col-3'>师傅服务</div>
                    <div className='col-3'>满意付款</div>
                    <div className='col-3'>订单质保</div>
                </div>
            ))
            setShow(true)
        }

        if (id === 3) {
            setOfTitle('关于我们')
            setOfinfo((
                <div style={{ 'lineHeight': '2' }}>

                    <div><strong>呜呜上门家庭维修</strong>，家庭维修专家，生活好帮手！</div>
                    <div><strong>呜呜上门</strong>成立于2022年2月，是一家互联网科技与实体结合的新型企业，拥有专业、优秀的线上开发，维护、推广团队，线下维修、服务、施工团队，我们以借助互联网的加持，推动服务行业做得更深更广。</div>
                    <div><strong>呜呜上门家庭维修</strong>，服务涵盖了家庭水电、家电、厨卫、灯具、电脑、办公等等项目，并且扩展了管道疏通、修锁换锁、门窗维修等项目，专业团队持证上岗，拥有电工、网络、弱电、高空作业等等施工资质。</div>
                    <div><strong>呜呜上门是一个年青团队，朝气蓬勃，蒸蒸日上！服务社会，方便万家。</strong></div>
                    <div><strong>呜呜上门家庭维修目前拥有，23个大类，七十多个小类的服务项目</strong>，涵盖几乎所有的家庭维保服务，拥有多个线下实体店面及加盟商户，服务范围越来越广，并在持续快速的增长。</div>
                    <div style={{ 'marginTop': '2rem', 'textAlign': 'center' }}>
                        <img src='http://st.epnz.com/image/index-ad.jpg' className="img-fluid" alt='ad01' />
                    </div>

                </div>
            ))
            setShow(true)
        }
    }

    return (
        <>
            <Header />
            <main>
                <div className='container item'>
                    <Item />
                </div>
                <div className="container">
                    <div className="shadow mb-5 mt-5 rounded count">
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <div className='info-box p-5'>
                                    <div className='title mb-3'>85+</div>
                                    <div className='info'>
                                        <div className='info-title mb-2'>服务项目</div>
                                        <div className='info-details'>总共8个大项，85+品类</div>
                                    </div>
                                </div>
                                <hr className='vertical dark'></hr>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className='info-box p-5'>
                                    <div className='title mb-3'>36+</div>
                                    <div className='info'>
                                        <div className='info-title mb-2'>服务区域</div>
                                        <div className='info-details'>服务于广州花都新雅街道36个社区</div>
                                    </div>
                                </div>
                                <hr className='vertical dark'></hr>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className='info-box p-5'>
                                    <div className='title mb-3'>1000+</div>
                                    <div className='info'>
                                        <div className='info-title mb-2'>服务人群</div>
                                        <div className='info-details'>线上社区粉丝1000+</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Popular />
                <div className='container service'>
                    <div className='mt-3 mb-5'>
                        <div className='content'>
                            <div className='row'>
                                <div className='col-3 col-md' onClick={() => openOffcanvas(1)}><i className="bi bi-houses"></i> 服务保障</div>
                                <div className='col-3 col-md' onClick={() => openOffcanvas(2)}><i className="bi bi-house-check"></i> 服务流程</div>
                                <div className='col-3 col-md' onClick={() => openOffcanvas(3)}><i className="bi bi-house-heart"></i>关于我们</div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-3 mb-5'>
                        <div className='content'>

                        </div>
                    </div>
                </div>
            </main>
            <div className='advertise'>
                <div className='container'>
                    <div>
                        <img src={ad01} className="img-fluid" alt='呜呜上门家庭维修' />
                    </div>
                </div>
            </div>
            <Footer />
            <Offcanvas show={show} onHide={handleClose} placement='start'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{ofTitle}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='container'>{ofInfo}</div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default App;
