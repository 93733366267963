import { WU } from './../config/app'

export default function Footer() {

    return (
        <footer>
            <div className='container'>
                <div className='copyright'>Copyright &copy; {WU.name} 2024 Powered by <strong><a href="http://www.wuwu.city">{WU.appName}</a></strong> V{WU.version}</div>
                <div className='company'>广州市花都区新雅呜呜家电维修部</div>
                <div className='beian'><a href="https://beian.miit.gov.cn/">粤ICP备2022027147号</a></div>
            </div>
        </footer>
    )
}