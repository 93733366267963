/*
 * @Author: 故乡情
 * @Date: 2022-02-15 20:36:28
 * @LastEditTime: 2024-02-26 00:21:36
 * @LastEditors: 故乡情
 * @Description: WuWu Project-Wap/PC
 * @FilePath: \wuwu-web\src\config\app.ts
 */

export const WU = {
    name: '呜呜上门',
    appName: 'WuWuCity',
    version: '0.1.10',
    webStaticUrl: 'http://st.epnz.com/upload/',
    webApiUrl: 'https://wu.epnz.com/',
    noImage: 'http://st.epnz.com/image/noImage.png'
}