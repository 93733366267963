import { useEffect } from 'react';

import logo from './../assets/icon/wuwu-logo.svg'

export default function Header() {

    useEffect(() => {

        return () => {
            // 相当于 componentWillUnmount
        }
    }, [])

    return (
        <header>
            <div className="container">
                <div className="logo">
                    <img src={logo} alt="WuWuCity logo" />
                </div>
                <div className='title'>
                    家庭维修专家，生活好帮手
                </div>
            </div>
        </header>
    )
}